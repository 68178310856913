<template>
  <div class="historial-clientes mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>
            Historial de clientes: {{ clientes.length }}
          </v-card-title>
          <v-card-subtitle>
            Visualiza el historial de cada uno de tus clientes de manera
            detallada.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  dense
                  :loading="loading_t"
                  :headers="headers"
                  :items="lista"
                  :search="search"
                  hide-default-footer
                  :items-per-page="perPage"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                >
                  <template v-slot:top>
                    <v-row no-gutters>
                      <v-col cols="10" md="12" sm="12">
                        <v-row no-gutters>
                          <v-col cols="11" md="5" sm="7"
                            ><v-text-field
                              outlined
                              rounded
                              dense
                              label="Buscar"
                              v-model="search"
                            ></v-text-field
                          ></v-col>
                          <v-col cols="12" md="4" sm="4"
                            ><div style="display: flex">
                              <v-subheader> Clientes X pag </v-subheader>
                              <div style="width: 120px">
                                <v-select
                                  :items="itemsPerPage"
                                  v-model="perPage"
                                  outlined
                                  dense
                                ></v-select>
                              </div></div
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-pagination
                          dense
                          v-model="page"
                          :length="pageCount"
                          :total-visible="6"
                        ></v-pagination>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.ultima="{ item }">
                    <span v-if="!item.ultima" style="color: #fb8c00">
                      Sin visitas agendadas
                    </span>
                    <span v-else>
                      {{ moment(item.ultima).format("ll") }}
                    </span>
                  </template>
                  <template v-slot:item.proxima="{ item }">
                    <span v-if="!item.proxima" style="color: #fb8c00">
                      Sin visitas agendadas
                    </span>
                    <span v-else>
                      {{ moment(item.proxima).format("ll") }}
                    </span>
                  </template>
                  <template v-slot:item.dias="{ item }">
                    <span v-if="item.dias > 29" style="color: red">
                      {{ item.dias }}
                    </span>
                    <span v-else style="color: green">{{ item.dias }}</span>
                  </template>
                  <template v-slot:item.ver="{ item }">
                    <router-link
                      :to="`detalle_cliente_historial/${item.id}`"
                      style="text-decoration: none"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="my-1"
                          >
                            <v-icon> mdi-eye </v-icon>
                          </v-btn>
                        </template>
                        <span>Ver cliente</span>
                      </v-tooltip>
                    </router-link>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    loading_t: false,
    perPage: 25,
    page: 1,
    pageCount: 0,
    itemsPerPage: [
      {
        text: 10,
        value: 10,
      },
      {
        text: 25,
        value: 25,
      },
      {
        text: 50,
        value: 50,
      },
      {
        text: 100,
        value: 100,
      },
      {
        text: 500,
        value: 500,
      },
    ],
    search: "",
    clientes: [],
    headers: [
      {
        text: "ID",
        value: "cedula",
        align: "start",
      },
      {
        text: "Cliente",
        value: "nombre",
      },
      {
        text: "Correo",
        value: "email",
        sortable: false,
      },
      {
        text: "Telefono",
        value: "telefono",
        sortable: false,
      },
      {
        text: "Dirección",
        value: "direccion",
        sortable: false,
      },
      {
        text: "Ultima Visita",
        value: "ultima",
        sortable: false,
      },
      {
        text: "Proxima visita",
        value: "proxima",
        sortable: false,
      },
      {
        text: "Dias sin visita",
        value: "dias",
        sortable: false,
      },
      {
        text: "Ver",
        value: "ver",
        sortable: false,
      },
    ],
  }),
  methods: {
    loadClientes() {
      this.clientes = [];
      this.loading_t = true;
      const body = {
        route: `/HistorialClientes`,
        params: {
          date: moment().format("YYYY-MM-DD"),
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadClientes();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      let clientes = this.clientes.map((cliente) => {
        const actual = moment().format("YYYY-MM-DD");

        let dias = 0

        if (cliente.ultima != null && cliente.ultima != 0) {
          if (cliente.proxima != null && cliente.proxima != 0) {
            dias = moment(cliente.proxima).diff(
              moment(cliente.ultima),
              "days"
            );
          } else {
            dias = moment(actual).diff(moment(cliente.ultima), "days");
          }
        }

        return Object.assign({dias}, cliente);
      });

      return clientes
    },
  },
};
</script>
