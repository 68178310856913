var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"historial-clientes mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Historial de clientes: "+_vm._s(_vm.clientes.length)+" ")]),_c('v-card-subtitle',[_vm._v(" Visualiza el historial de cada uno de tus clientes de manera detallada. ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"dense":"","loading":_vm.loading_t,"headers":_vm.headers,"items":_vm.lista,"search":_vm.search,"hide-default-footer":"","items-per-page":_vm.perPage,"page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10","md":"12","sm":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11","md":"5","sm":"7"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-subheader',[_vm._v(" Clientes X pag ")]),_c('div',{staticStyle:{"width":"120px"}},[_c('v-select',{attrs:{"items":_vm.itemsPerPage,"outlined":"","dense":""},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-pagination',{attrs:{"dense":"","length":_vm.pageCount,"total-visible":6},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]},proxy:true},{key:"item.ultima",fn:function(ref){
var item = ref.item;
return [(!item.ultima)?_c('span',{staticStyle:{"color":"#fb8c00"}},[_vm._v(" Sin visitas agendadas ")]):_c('span',[_vm._v(" "+_vm._s(_vm.moment(item.ultima).format("ll"))+" ")])]}},{key:"item.proxima",fn:function(ref){
var item = ref.item;
return [(!item.proxima)?_c('span',{staticStyle:{"color":"#fb8c00"}},[_vm._v(" Sin visitas agendadas ")]):_c('span',[_vm._v(" "+_vm._s(_vm.moment(item.proxima).format("ll"))+" ")])]}},{key:"item.dias",fn:function(ref){
var item = ref.item;
return [(item.dias > 29)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.dias)+" ")]):_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(item.dias))])]}},{key:"item.ver",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("detalle_cliente_historial/" + (item.id))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"color":"primary","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver cliente")])])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }